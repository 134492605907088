<template>
  <div class='abstract-submission-container flex flex-col lg:flex-row items-stretch  min-h-screen'>
    <div
      class='w-full lg:w-80 px-8 pt-2 pb-8 lg:py-16 text-gray-50 flex flex-col items-start justify-start relative'
      :style='backgroundStyle'>
      <h1
        class='text-normal font-light border border-solid border-transparent py-1 pl-1 pr-4 rounded-md hover:border-gray-50 cursor-pointer'
        @click='goToMain'
        style='margin-left:-1.5rem;'>
        <arrow-narrow-left-icon class='h-4 inline-block' />
        Back to Conference Site
      </h1>
      <h1
        class='text-normal font-light border border-solid border-transparent py-1 pl-1 rounded-md hover:border-gray-50 cursor-pointer'
        @click='goToAbstractSubmissionGuideline'
        style='margin-left:-1.5rem;'>
        <arrow-narrow-left-icon class='h-4 inline-block' />
        Guideline for abstract submission
      </h1>
      <h1 class='text-2xl lg:text-3xl font-light lg:mt-4'>My Abstract Submissions</h1>
      <button
        class='logout-button text-xs text-gray-50 uppercase hover:shadow-md border border-transparent hover:border-gray-50 rounded-md py-2 absolute'
        @click='logoutApp'>
        Logout
      </button>
    </div>
    <div class='p-8 lg:p-12 overflow-y-auto h-screen flex-grow'>
      <div class='max-w-2xl'>
        <h1 class='text-2xl font-semibold mb-4'>Current Submissions ({{myAbstracts.length}})</h1>
        <abstract-submission-preview v-for='abstract in myAbstracts'
          :key='`my-abstract-${abstract.id}`'
          :abstract='abstract'
          :show-accepted-status-badge='true'
          class='overflow-y-auto border rounded-md mb-8'
          :class='isAbstractSubmissionPeriodPreviewClass'
          style='height: 480px;'
          @click.native='gotoAbstract(abstract)' />
        <div v-if='hasNoAbstracts && isAbstractSubmissionPeriod'
          class='rounded-md bg-gray-100 py-8 px-4 mb-8 text-center text-gray-600'>
          <p class='mb-2'>You have not yet submitted any abstracts.</p>
          Please <span class='underline cursor-pointer hover:bg-gray-300' @click='gotoNewAbstract'>submit an abstract</span>.
        </div>
        <div v-else-if='hasNoAbstracts && !isAbstractSubmissionPeriod'>
          <p class='mb-2'>Abstract submission deadline has passed.</p>
        </div>
        <button
          v-if='isAbstractSubmissionPeriod'
          @click='gotoNewAbstract'
          class='py-3 px-8 text-sm uppercase text-center bg-gray-900 text-white rounded-md hover:shadow-md opacity-80 hover:opacity-100'>
          Submit A New Abstract
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapState} from 'vuex'
import { ArrowNarrowLeftIcon } from 'vue-tabler-icons'
import AbstractSubmissionPreview   from '@/components/abstracts/AbstractSubmissionPreview.vue'
import dayjs from 'dayjs'

export default {
  name: 'AbstractSubmissions',
  components: {
    AbstractSubmissionPreview,
    ArrowNarrowLeftIcon,
  },
  computed: {
    ...mapGetters('events',[
      'eventMainThemeColor',
      'showingEventId',
      'eventConfigAbstractSubmissionDeadline',
    ]),
    ...mapState('abstracts', [
      'myAbstracts',
    ]),
    ...mapState('users', [
      'hasValidToken',
    ]),
    backgroundStyle () {
      return `background-color: ${this.eventMainThemeColor};`
    },
    hasNoAbstracts () {
      return this.myAbstracts.length == 0
    },
    isAbstractSubmissionPeriod () {
      return dayjs(this.eventConfigAbstractSubmissionDeadline).isAfter(dayjs(new Date()))
    },
    isAbstractSubmissionPeriodPreviewClass () {
      return this.isAbstractSubmissionPeriod ? 'cursor-pointer' : ''
    }
  },
  methods: {
    ...mapActions('users', [
      'checkTokenStatus',
      'logout',
    ]),
    ...mapActions('abstracts', [
      'getMyAbstracts',
    ]),
    logoutApp () {
      this.logout().then(() => {
        this.$router.push({name: 'Login'})
      })
    },
    goToMain () {
      this.$router.push({name: 'Main'})
    },
    goToAbstractSubmissionGuideline () {
      this.$router.push({name: 'AbstractSubmissionGuideline'})
    },
    gotoAbstract (abstract) {
      if (this.isAbstractSubmissionPeriod) {
        this.$router.push({name: 'AbstractSubmission', query: { abstract_submission_id: abstract.id }})
      }
    },
    gotoNewAbstract () {
      if (this.isAbstractSubmissionPeriod) {
        this.$router.push({name: 'AbstractSubmission', query: { abstract_submission_id: 0 }})
      }
    },
  },
  created () {
    this.checkTokenStatus().then(() => {
      if (!this.hasValidToken) {
        this.$router.replace({name: 'Login', query: {redirect_route: 'AbstractSubmissions'} })
      } else {
        this.getMyAbstracts(this.showingEventId)
      }
    })
  },
  mounted () {
    this.$emit('update:layout', 'empty-page')
  },
  beforeDestroy () {
    this.$emit('update:layout', 'default-layout')
  },
}
</script>

<style lang='scss'>
  .abstract-submission-container .logout-button {
    width: auto;
    @apply top-2 right-2;
  }

  @media (min-width: 1024px) {
    .abstract-submission-container .logout-button {
      width: calc(100% - 4rem);
      @apply top-auto right-auto bottom-4;
    }
  }

</style>
